import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { CodedChoiceFeatureField } from '../feature-field';

@Component({
    templateUrl: './coded-choice-field.component.html',
    selector: 'coded-choice-field'
})
export class CodedChoiceFieldComponent implements OnInit {
    @Input()
    field: CodedChoiceFeatureField;

    @Input()
    editMode: boolean;

    public optionSelected: { [description: string]: { selected: boolean } } = {};
    public selectedValue: string;

    constructor(private cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.selectedValue = this.field.value;
        this.field.options.forEach(option => {
            this.optionSelected[option.description] = { selected: false };
        });

        if (this.selectedValue) {
            this.setSelectedDescriptionValue(this.selectedValue);
        } else {
            this.initCheckOptionsAndOtherField();
        }
    }

    public toggleOption(code: string): void {
        const selectedOption = this.field.options.find(option => option.code === code);

        if (selectedOption) {
            const selectedDescription = selectedOption.description;
            const selectedCode = selectedOption.code;

            Object.keys(this.optionSelected).forEach(description => {
                this.optionSelected[description].selected = description === selectedDescription;
            });
            this.field.value = selectedCode;
        } else {
            // Retain old selected data if code is invalid
            Object.values(this.optionSelected).forEach(option => (option.selected = false));
        }

        this.initCheckOptionsAndOtherField();
    }

    private setSelectedDescriptionValue(code: string): boolean {
        const findCode = this.field.options.find(option => option.code === code);

        if (!code || !findCode) {
            return false;
        }

        const description = findCode.description;
        return (this.optionSelected[description].selected = !!description);
    }

    private initCheckOptionsAndOtherField(): void {
        this.checkOptions();
        this.checkOtherField(this.selectedValue);
        this.cdRef.detectChanges();
    }

    private checkOptions(): void {
        // disable "save" button if required and no value has been set.
        this.field.hasError = this.field.required && !this.field.value;
    }

    private checkOtherField(code: string): void {
        const findCode = this.field.options.find(option => option.code === code);
        // disable "save" button if required and no other value has no string.
        this.field.hasError = this.field.required && !findCode;
    }
}
