import { CdkDrag, CdkDragDrop, CdkDropList, Point } from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import * as _ from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { bufferTime, takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { BooleanConverter, InputConverter } from 'src/app/shared/common/components/input-converter.decorator';
import { Application } from 'src/app/shared/map-data-services/application';
import {
    AutoFieldModelType,
    Field,
    FieldType,
    GroupField,
    LayoutFieldType
} from 'src/app/shared/template-services/field';
import { FieldService } from 'src/app/shared/template-services/field/field.service';
import { FieldsStoreService } from 'src/app/shared/template-services/field/fields-store.service';
import { Rule } from 'src/app/shared/template-services/rule/rule';
import { Template } from 'src/app/shared/template-services/template';

import { TemplateService } from 'src/app/shared/template-services/template.service';
import { ConnectedDropListService } from '../../connected-drop-list.service';
import { RuleSelectionStreamService } from '../../rules-panel/rule-selection-stream.service';
import { TemplateEditorTab, TemplateEditorTabId } from '../../tabs-list/current-tab-stream.service';
import { TemplateRouteData } from '../../template-editor-route-resolver.service';

@Component({
    selector: 'field-list',
    templateUrl: './field-list.component.html'
})
export class FieldListComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input()
    template: Template;

    @Input()
    currentTab: TemplateEditorTab;

    @Input()
    fields: Field[] = [];

    @Input()
    parentField: Field;

    @Input()
    selectedField: Field;

    @Output()
    fieldSelected = new EventEmitter<Field>();

    @Input()
    parentIndex: number;

    @Input()
    currentProjectDetails: TemplateRouteData;

    @Input()
    templateEditorConfig: { tabs: { fields: { fieldsGroup: any[]; enableFieldConditions: { [key: string]: any } } } };

    @Input()
    application: Application;

    @Input()
    @InputConverter(BooleanConverter)
    noNestedGroups = false;

    @ViewChild('dropList', { read: CdkDropList, static: true })
    dropList: CdkDropList;

    @ViewChild('fieldListContainer', { static: true }) fieldListContainer: ElementRef;

    blockedDragOnInputElement = false;

    @HostListener('body:click', ['$event'])
    bodyclick(event: MouseEvent): void {
        this.clickStream.next(event);
    }

    connectedLists$: Observable<CdkDropList[]>;
    predicate = this.canDropPredicate();
    selectedRule: Rule;

    private clickStream = new Subject<MouseEvent>();
    private destroyed = new Subject<void>();
    private dropzoneHovered = false;

    constructor(
        private connectedDropLists: ConnectedDropListService,
        private fieldsStoreService: FieldsStoreService,
        private fieldService: FieldService,
        private templateService: TemplateService,
        private translate: TranslationService,
        private ruleSelectionStream: RuleSelectionStreamService // private templatesStoreForComponent: TemplateStoreForComponent
    ) {}

    ngOnInit(): void {
        // trap single clicks only for closing fields so thay double click
        // to add new field still works
        this.clickStream.pipe(takeUntil(this.destroyed), bufferTime(250)).subscribe(events => {
            if (events.length === 1) {
                this.closeField(events[0]);
            }
        });

        this.ruleSelectionStream.ruleSelectionStream.pipe(takeUntil(this.destroyed)).subscribe(selectedRule => {
            this.selectedRule = selectedRule;
        });

        this.connectedLists$ = this.connectedDropLists.allLists$;
    }

    ngAfterViewInit(): void {
        this.connectedDropLists.addList(this.dropList);
    }

    closeField(e: MouseEvent): void {
        if (this.selectedField == null) {
            return;
        } else if (e.target) {
            if (
                (e.target as HTMLTextAreaElement)['classList'].contains('scroll-content') ||
                (e.target as HTMLTextAreaElement)['classList'].contains('scroll-container')
            ) {
                this.selectedField = null;
            }
        }
    }

    getParentPosition(): number {
        if (this.parentField) {
            return this.template.fields.findIndex(field => field.uuid === this.parentField.uuid);
        }
    }

    drop(event: CdkDragDrop<void>): void {
        if (event.item.data.field) {
            this.droppedExistingField(
                event.item.data.field,
                event.item.data.parentField,
                event.item.data.coords,
                event.currentIndex
            );
        } else {
            if (event.item.data.fieldType === FieldType.Sensor) {
                this.fieldsStoreService.currentTemplate.next(this.template);
                this.fieldsStoreService.currentApplication.next(this.application);
                this.fieldsStoreService.draggedSensorDataStream.next(event);

                this.fieldsStoreService.openPopupTo('template/editor/sensor-options');
                return;
            }

            this.droppedToCreateField(
                event.item.data.fieldType,
                event.item.data.subtype,
                event.item.data.representsMultipleFields,
                event.currentIndex
            );
        }
    }

    droppedExistingField(
        dragged: Field,
        parentOfDraggedField: Field,
        dragLocation: number[],
        currentIndex: number
    ): void {
        let drop0 = this.parentField ? this.getParentPosition() : currentIndex;
        let drop1 = this.parentField ? currentIndex : undefined;

        dragged.groupId = parentOfDraggedField ? parentOfDraggedField.uuid : null;
        this.template.repositionField(dragged, dragLocation, [drop0, drop1]);

        this.template.assessRuleErrors();
        if (this.currentTab.id === TemplateEditorTabId.RULE) {
            this.template.assessFields(this.selectedRule);
        }
    }

    async droppedToCreateField(
        fieldType: FieldType | LayoutFieldType | AutoFieldModelType,
        fieldSubType: string,
        representsMultipleFields: boolean,
        dropIndex: number
    ): Promise<void> {
        let drop0 = this.parentField ? this.getParentPosition() : dropIndex;
        let drop1 = this.parentField ? dropIndex : undefined;

        if (representsMultipleFields) {
            const fieldsMap = await this.fieldsStoreService.getFieldsMap(
                this.application.name,
                this.application.category
            );
            Object.values(fieldsMap).forEach(fieldDef => {
                if (fieldDef.application.layoutSchema.representedByFieldType === fieldType) {
                    this.template.insertNewField({
                        templateService: this.templateService,
                        fieldsStore: this.fieldsStoreService,
                        translate: this.translate,
                        application: this.application,
                        fieldType: fieldDef.fieldType,
                        fieldSubType: null,
                        position: [drop0, drop1],
                        groupId: this.parentField ? this.parentField.uuid : null
                    });

                    if (this.parentField) {
                        drop1++;
                    } else {
                        drop0++;
                    }
                }
            });
        } else {
            this.template.insertNewField({
                templateService: this.templateService,
                fieldsStore: this.fieldsStoreService,
                translate: this.translate,
                application: this.application,
                fieldType,
                fieldSubType,
                position: [drop0, drop1],
                groupId: this.parentField ? this.parentField.uuid : null
            });
        }

        if (fieldType === LayoutFieldType.PageHeader && dropIndex > 0) {
            this.checkAndInsertTopPageHeader();
        }

        this.template.assessRuleErrors();
        if (this.currentTab.id === TemplateEditorTabId.RULE) {
            this.template.assessFields(this.selectedRule);
        }
    }

    async onDuplicateField(fieldToDuplicate: Field): Promise<Field> {
        let drop0 = this.parentField ? this.getParentPosition() : this.template.fields.length;
        let drop1;

        // Add new (group) field
        const newField = await this.template.insertNewField({
            templateService: this.templateService,
            fieldsStore: this.fieldsStoreService,
            translate: this.translate,
            application: this.application,
            fieldType: fieldToDuplicate.type,
            fieldSubType: null,
            position: [drop0, drop1],
            groupId: this.parentField ? this.parentField.uuid : null,
            sequenceIncreaseValue: null,
            duplicateGroupField: fieldToDuplicate
        });

        if (fieldToDuplicate.fields?.length) {
            const newGroupIndex = this.template.fields.findIndex(field => field.uuid === newField.uuid); // Get index of new group field
            drop0 = newGroupIndex;
            drop1 = newGroupIndex + 1;

            // Add child fields with new group uuid
            for (const duplicateChildField of fieldToDuplicate.fields) {
                const newChildField = await this.template.insertNewField({
                    templateService: this.templateService,
                    fieldsStore: this.fieldsStoreService,
                    translate: this.translate,
                    application: this.application,
                    fieldType: duplicateChildField.type,
                    fieldSubType: null,
                    position: [drop0, drop1],
                    groupId: newField.uuid,
                    sequenceIncreaseValue: null,
                    duplicateGroupField: null,
                    duplicateChildField
                });
                drop1++;
            }

            // Needs to run after new fields have been selected in field-item component
            setTimeout(() => this.fieldSelected.emit(this.template.fields[newGroupIndex]));
            this.copyRulesOnDuplicatedField(fieldToDuplicate, newGroupIndex);

            return newField;
        }
    }

    async copyRulesOnDuplicatedField(sourceField: Field, newGroupIndex: number): Promise<Rule[]> {
        const rules: Rule[] = this.template.rules;
        const sourceFieldIndex = this.template.fields.findIndex(field => field.uuid === sourceField.uuid);
        const childSourceFields = this.template.fields[sourceFieldIndex]?.fields;
        const childSourceFieldIds = childSourceFields.map(field => field.uuid);
        const duplicatedField = this.template.fields[newGroupIndex];
        const duplicatedRules: Rule[] = [];
        const rulesToDuplicate: Rule[] = this.template.rules.filter(
            rule =>
                rule.conditions.every(condition => childSourceFieldIds.includes(condition.sourceUuid)) &&
                rule.targets.every(target => childSourceFieldIds.includes(target.targetUuid))
        );

        if (newGroupIndex && rulesToDuplicate.length) {
            for (const rule of rulesToDuplicate) {
                // create a new rule without adding on actual state
                const newRule = new Rule();

                // add new conditions and targets and re-assign rule ids to new fields
                childSourceFields.forEach((field, i) => {
                    rule.conditions.forEach(condition => {
                        condition = _.cloneDeep(rule.getCondition(field.uuid));
                        if (condition) {
                            condition.sourceUuid = duplicatedField.fields[i].uuid;
                            this.template.setSource(duplicatedField.fields[i], newRule, condition.type, condition);
                        }
                    });

                    rule.targets.forEach(target => {
                        target = _.cloneDeep(rule.getTarget(field.uuid));
                        if (target) {
                            target.targetUuid = duplicatedField.fields[i].uuid;
                            this.template.setTarget(duplicatedField.fields[i], newRule, target);
                        }
                    });
                });

                // organise all new rules in proper order
                duplicatedRules.push(newRule);
            }

            // prepend all new rules to old rules
            this.template.rules = duplicatedRules.concat(rules);

            return duplicatedRules;
        }
    }

    checkAndInsertTopPageHeader(): void {
        if (this.template.fields.length && this.template.fields[0].type !== LayoutFieldType.PageHeader) {
            this.template.insertNewField({
                templateService: this.templateService,
                fieldsStore: this.fieldsStoreService,
                translate: this.translate,
                application: this.application,
                fieldType: LayoutFieldType.PageHeader,
                fieldSubType: LayoutFieldType.PageHeader,
                position: [0],
                groupId: null,
                sequenceIncreaseValue: 1
            });
        }
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    isDragDisabled(field: Field | GroupField): boolean {
        if (field.type === LayoutFieldType.Group) {
            let groupActive = false;
            for (const groupField of field.fields) {
                if (this.selectedField && groupField.uuid === this.selectedField.uuid) {
                    groupActive = true;
                }
            }
            return groupActive;
        }
        return false;
    }

    // This method is used by drag and drop to decide if the dragged element is
    // able to be dropped in a specific drop list. Because the CDK drag and drop doesn't really work with nesting,
    // we have to specifically filter out the parent list whenever a child list is hovered over.
    private canDropPredicate(): (drag: CdkDrag<any>, drop: CdkDropList<any>) => boolean {
        return (drag: CdkDrag<any>, drop: CdkDropList<any>): boolean => {
            // Must return true the first time so the parent list can be accessed otherwise
            // this.pointInsideOf(pointerPosition, fromBounds) will always return false when dragging item from child list
            // to parent list.
            if (!this.dropzoneHovered) {
                return (this.dropzoneHovered = true);
            }

            const fromBounds = drag.dropContainer.element.nativeElement.getBoundingClientRect();
            const toBounds = drop.element.nativeElement.getBoundingClientRect();

            let typ = drag.data.field ? drag.data.field.type : drag.data.fieldType;

            if (!this.intersect(fromBounds, toBounds)) {
                return !(
                    (typ === LayoutFieldType.PageHeader || typ === LayoutFieldType.Group || typ === FieldType.Sensor) &&
                    this.noNestedGroups
                );
            }

            // This gross but allows us to access a private field for now.
            const pointerPosition: Point = drag['_dragRef']['_pointerPositionAtLastDirectionChange'];
            // They Intersect with each other so we need to do some calculations here.
            if (this.insideOf(fromBounds, toBounds)) {
                return !this.pointInsideOf(pointerPosition, fromBounds);
            }

            if (this.insideOf(toBounds, fromBounds) && this.pointInsideOf(pointerPosition, toBounds)) {
                return !(
                    (typ === LayoutFieldType.PageHeader || typ === LayoutFieldType.Group || typ === FieldType.Sensor) &&
                    this.noNestedGroups
                );
            }

            // This gets hit when first entering a child list. It must return true in this case so the toBounds get
            // calculated correctly the next time this is run otherwise this.pointInsideOf(pointerPosition, toBounds)
            // will always return false.
            return true;
        };
    }

    private insideOf(innerRect: DOMRect, outerRect: DOMRect): boolean {
        return (
            innerRect.left >= outerRect.left &&
            innerRect.right <= outerRect.right &&
            innerRect.top >= outerRect.top &&
            innerRect.bottom <= outerRect.bottom &&
            !(
                innerRect.left === outerRect.left &&
                innerRect.right === outerRect.right &&
                innerRect.top === outerRect.top &&
                innerRect.bottom === outerRect.bottom
            )
        );
    }

    private intersect(r1: DOMRect, r2: DOMRect): boolean {
        return !(r2.left > r1.right || r2.right < r1.left || r2.top > r1.bottom || r2.bottom < r1.top);
    }

    private pointInsideOf(position: Point, rect: DOMRect): boolean {
        return (
            position.x >= rect.left && position.x <= rect.right && position.y >= rect.top && position.y <= rect.bottom
        );
    }

    public updateAllFields(): void {
        this.fieldService.updateAllFieldsStream$.next(null);
    }

    // cdk workaround: Disables dragging on input child elements
    public disableDraggingOnInputs(): void {
        const inputElements = this.fieldListContainer.nativeElement.querySelectorAll(
            '.field-header-inputs input, .field-header-inputs label'
        ) as HTMLInputElement[];
        const propertiesEditSection = this.fieldListContainer.nativeElement?.querySelectorAll('.properties-edit');

        // prevents multiple event listeners from being duplicated
        this.removeAllEventListeners(propertiesEditSection);
        this.removeAllEventListeners(inputElements);

        // disabled draggable for `div.properties-edit` section if visible
        if (propertiesEditSection) {
            propertiesEditSection.forEach((section: HTMLElement) => {
                section.addEventListener('mouseover', () => this.preventDragInputElement());
                section.addEventListener('mouseleave', () => this.allowDragEventAgain());
            });
        }

        // select all input elements and add event listeners if visible
        if (inputElements.length) {
            inputElements.forEach((input: HTMLInputElement) => {
                // prevents dragging when mouse is over the input element
                input.addEventListener('mouseover', () => this.preventDragInputElement());
                input.addEventListener('mouseup', () => this.preventDragInputElement());
                input.addEventListener('click', () => this.preventDragInputElement());
                input.addEventListener('focus', () => this.preventDragInputElement());
                input.addEventListener('blur', () => this.preventDragInputElement());

                // re-allow dragging when mouse leaves the input element
                input.addEventListener('mouseleave', () => this.allowDragEventAgain());
            });
        }
    }

    private removeAllEventListeners(inputs: HTMLInputElement[]): void {
        inputs.forEach((input: HTMLInputElement) => {
            input.removeEventListener('mouseover', () => this.preventDragInputElement());
            input.removeEventListener('mouseup', () => this.preventDragInputElement());
            input.removeEventListener('click', () => this.preventDragInputElement());
            input.removeEventListener('blur', () => this.preventDragInputElement());
            input.removeEventListener('focus', () => this.preventDragInputElement());

            input.removeEventListener('mouseleave', () => this.allowDragEventAgain());
        });
    }

    private preventDragInputElement(): void {
        this.blockedDragOnInputElement = true;
    }

    private allowDragEventAgain(): void {
        // this will be triggered once mouse leaves the input element
        this.blockedDragOnInputElement = false;
    }
}
