import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    forwardRef,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { KeyCodes } from 'src/app/shared/common/key-codes';

import { CloneUtils } from '../../utility/clone-utils';
import { ValueAccessorBase } from '../value-accessor-base';

/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
// the ngModel for this component is the selected options

export const OtherWordstring = 'Other';

@Component({
    selector: 'gsp-dropdown',
    templateUrl: './gsp-dropdown.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GspDropDownComponent),
            multi: true
        }
    ]
})
export class GspDropDownComponent
    extends ValueAccessorBase<string | string[]>
    implements OnInit, OnChanges, DoCheck, OnDestroy, AfterViewInit
{
    private destroyed = new Subject<void>();

    @Input()
    idField = 'id';

    @Input()
    textField = 'text';

    // the placeholder to show on the input
    @Input()
    placeholder = 'TC.Common.SelectOption';
    placeholderGreyText = false;

    // force hide the search box from dropdown
    @Input()
    hideSearchBox = false;

    @Input()
    multiple = false; // allow multiple options to be selected

    @Input()
    includeNoSelection = false; // include a 'no selection' option in the dropdown
    @Input()
    noSelectionText = '---'; // text to show for the 'no selection' option
    @Input()
    multiPlaceholder = 'TC.Common.ClickOption'; // placeholder to show on multi select input
    searchPlaceholder = 'TC.Common.SearchEllipsis'; // placeholder to show on dropdown search input.search not working yet
    minSearchChars = 2;
    emptyText = 'TC.Common.NoOptionsFound'; // text to show in the dropdown when no results are found
    tabIndex = 0;
    showDropdown = false;
    showSearchInput: boolean;
    selectedText: string;
    selectedIconClass: string;
    selectedGeometryType: string;
    selectedColor: string;
    overlapMenu: ElementRef;

    filteredOptions: any[] = [];

    private _options: any[] = [];

    private eventUnListeners: any[] = [];

    @Input()
    public get options(): any[] {
        return this._options;
    }

    public set options(optionsArray: any[]) {
        const singleLayeredArray = optionsArray.every(x => typeof x === 'string');
        this._options = singleLayeredArray
            ? (optionsArray = optionsArray.map(item => {
                  return {
                      id: item as string,
                      text: item
                  };
              }))
            : CloneUtils.cloneDeep(optionsArray);
        this.filteredOptions = this.getFilteredOptions();
        this.updateOptionSelection();
        this.updateText();
    }

    @Input()
    addBg = false;

    @Input()
    dropdownContainerClass = 'dropdown-container';

    @Input()
    dropdownUniqueClass = '';

    private _filterQuery: string;
    @Input()
    public get filterQuery(): string {
        return this._filterQuery;
    }
    public set filterQuery(v: string) {
        this._filterQuery = v;
        this.filteredOptions = this.getFilteredOptions();
    }

    @Input()
    sort: boolean;

    @Input()
    ngDisabled = false;

    @Input()
    addIcon: boolean;

    @Input()
    addDescription: boolean;

    @Input()
    minimumSearchLimit = 30;

    @Input()
    overlapClass: string;

    @Input()
    forceDefaultAlignment = false;

    /**
     * If set true, the dropdown will include a geomIcon in dropdown. This will display the color and geometry type for all options.
     */
    @Input()
    geomIcon = false;

    /**
     * If set true, the dropdown will overlap the parent container to prevent overflow issues. dropdown options will be rendered inside <body>.
     */
    @Input()
    overlapMenuOn = false;

    /**
     * Use this input if there is a dropdown in a modal that should dropup when it exceeds the height of the modal body
     */
    @Input()
    public shouldDropup = false;

    public _id = '';
    @Input()
    public set id(id: string) {
        if (id) {
            this._id = id + '-dropdown';
        }
    }
    public get id(): string {
        return this._id;
    }

    @ViewChild(NgModel, { static: true }) model: NgModel;

    @ViewChildren('dropdownElement')
    dropdownElements: QueryList<any>;

    @ViewChild('dropdownContainer', { static: true })
    dropdownContainer: ElementRef;

    @ViewChild('dropdownMenu', { static: true })
    dropdownMenu: ElementRef;

    @ViewChild('searchBox', { static: true })
    searchBox: ElementRef;

    @ViewChild('toggleButton')
    toggleButton: ElementRef;

    @HostListener('body:click', ['$event'])
    bodyclick(event: MouseEvent): void | boolean {
        if (
            this.dropdownContainer.nativeElement.contains(event.target) ||
            this.searchBox.nativeElement.contains(event.target)
        ) {
            // don't close dropdown after click - (if conditions above are met)
            return false;
        }

        if (this.showDropdown) {
            this.closeDropdown();
        }
    }

    @HostListener('body:keydown', ['$event'])
    bodykey(event: KeyboardEvent): void {
        if (this.showDropdown) {
            this.keyboardListener(event);
        }
    }

    constructor(private el: ElementRef, private renderer: Renderer2, private cdRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        if (this.overlapMenuOn) {
            this.overlapMenu = this.dropdownMenu;
            this.renderer.setAttribute(this.overlapMenu.nativeElement, 'id', this._id);
            this.renderer.appendChild(document.body, this.overlapMenu.nativeElement);
        }

        if (this.multiple) {
            // Remove duplicate options if any.
            const uniqueOptions = new Map();
            this.options.forEach(option => {
                uniqueOptions.set(option.id, option);
            });
            this.options = Array.from(uniqueOptions.values());
        }

        if (this.includeNoSelection) {
            this.options.unshift({
                [this.idField]: null,
                [this.textField]: this.noSelectionText
            });
        }
    }

    ngOnChanges(): void {
        this.updateOptionSelection();
        this.updateText();
    }

    ngAfterViewInit(): void {
        if (this._id) {
            this.renderer.setAttribute(this.dropdownContainer.nativeElement, 'id', this._id);
        }
    }

    // TODO: This doesn't look right. Check if there's another way, if so we can remove the
    //       no-conflicting-lifecycle exemption at the top of this file
    ngDoCheck(): void {
        // to force updateText on option textField change
        if (!this.multiple) {
            this.options.forEach((option: any) => {
                if (option[this.idField] === this.value && option[this.textField] !== this.selectedText) {
                    this.updateOptionSelection();
                    this.updateText();
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
        if (this.overlapMenuOn) {
            this.renderer.removeChild(document.body, this.overlapMenu.nativeElement);
        }
    }

    innerChanged(): void {
        this.updateOptionSelection();
        this.updateText();
    }

    checkEnableSearch(): boolean {
        return this.minimumSearchLimit !== 0 && this.options.length > this.minimumSearchLimit ? true : false;
    }

    toggleDropdown(e: MouseEvent): void {
        // commented for handling multiple open dropdowns
        // e.preventDefault();
        // e.stopPropagation();

        if (!this.ngDisabled) {
            // To close other dropdown and open the current dropdown

            this.showSearchInput = this.checkEnableSearch();

            this.filterQuery = '';

            if (!this.showDropdown) {
                this.showDropdown = true;
                // reset tabindex, etc. for keyboard scrolling of menu
                this.tabIndex = 0;

                // Added run cd check to get offsetParent prop since dropdown is (display: none) during function run
                this.cdRef.detectChanges();

                // if the menu will overflow container to the right the align to right of parent
                if (
                    this.dropdownMenu &&
                    this.dropdownMenu.nativeElement &&
                    this.dropdownMenu.nativeElement.offsetParent &&
                    this.dropdownMenu.nativeElement.offsetParent.clientWidth <
                        this.dropdownMenu.nativeElement.offsetParent.scrollWidth &&
                    !this.forceDefaultAlignment &&
                    !this.overlapMenuOn
                ) {
                    this.dropdownMenu.nativeElement.classList.add('right-align');
                }

                // In modals , if the dropdown exceeds the height of the modal body,then the dropdown should become a 'dropup'
                if (this.shouldDropup) {
                    const modal = this.el.nativeElement.closest('.modal-body');

                    if (
                        this.dropdownMenu.nativeElement &&
                        modal.getBoundingClientRect().bottom <
                            this.dropdownMenu.nativeElement.getBoundingClientRect().bottom &&
                        !this.overlapMenuOn
                    ) {
                        this.dropdownMenu.nativeElement.style.top =
                            '-' + this.dropdownMenu.nativeElement.offsetHeight + 'px';
                    }
                } else {
                    // if the dropdown exceeds the height of the page,then the dropdown should become a 'dropup'
                    if (
                        this.dropdownMenu.nativeElement &&
                        window.innerHeight < this.dropdownMenu.nativeElement.getBoundingClientRect().bottom
                    ) {
                        this.dropdownMenu.nativeElement.style.top =
                            '-' + this.dropdownMenu.nativeElement.offsetHeight + 'px';
                    }
                }

                // if parent has .filter-accordion class, render it in app-root
                if (this.overlapMenuOn) {
                    const dropdownContainer = this.el.nativeElement.querySelector('.dropdown-container');
                    // position dropdown menu to dropdownContainer's outer height
                    this.overlapMenu.nativeElement.style.top = dropdownContainer.getBoundingClientRect().bottom + 'px';
                    this.overlapMenu.nativeElement.style.left = dropdownContainer.getBoundingClientRect().left + 'px';

                    this.overlapMenu.nativeElement.style.width = dropdownContainer.getBoundingClientRect().width + 'px';
                    this.overlapMenu.nativeElement.style.display = 'block';
                    this.overlapMenu.nativeElement.style.position = 'absolute';
                }
            } else {
                this.closeDropdown();
            }
        }
    }

    closeDropdown(): void {
        this.showDropdown = false;
        this.filterQuery = '';
        if (this.overlapMenuOn) {
            this.overlapMenu.nativeElement.style.position = 'static';
            this.overlapMenu.nativeElement.style.display = 'none';
            this.overlapMenu.nativeElement.style.top = 'auto';
            this.overlapMenu.nativeElement.style.left = 'auto';
        }
    }

    get selectedOptions(): any[] {
        return this.options ? this.options.filter(x => x._selected === true) : [];
    }

    scrollMenu(e: KeyboardEvent, tabIndex: number): void {
        e.preventDefault();
        e.stopPropagation();
        this.dropdownElements.toArray()[tabIndex].nativeElement.focus();
    }

    keyboardListener(e: KeyboardEvent): void {
        const key = e.key;

        // ESC key (close)
        if (key === KeyCodes.ESCAPE) {
            this.closeDropdown();
        }

        // Enter key (apply selection)
        if (key === KeyCodes.ENTER) {
            this.select(e, this.filteredOptions[this.tabIndex]);
        } else if (key === KeyCodes.DOWN_ARROW || (!e.shiftKey && key === KeyCodes.TAB)) {
            // next element ( tab, down & right key )

            this.tabIndex++;
            if (this.tabIndex > this.dropdownElements.length - 1) {
                this.tabIndex = 0;
            }
            this.scrollMenu(e, this.tabIndex);
        } else if (key === KeyCodes.UP_ARROW || (e.shiftKey && key === KeyCodes.TAB)) {
            // prev element ( shift+tab, up & left key )

            this.tabIndex--;
            if (this.tabIndex < 0) {
                this.tabIndex = this.dropdownElements.length - 1;
            }
            this.scrollMenu(e, this.tabIndex);
        }
    }

    /**
     * update the selected options on the scope
     */
    select(e: KeyboardEvent, option: any): void {
        e.preventDefault();
        e.stopPropagation();

        if (this.multiple) {
            this.handleMultipleSelect(option);
            return;
        } else {
            this.value = option[this.idField];
        }

        this.closeDropdown();
    }

    getFilteredOptions(): any[] {
        let result = this.options;

        if (this.filterQuery) {
            // return any option that has a string property with matching substring
            result = result.filter(o =>
                o[this.textField].toString().toLowerCase().includes(this.filterQuery.toLocaleLowerCase())
            );
        }

        if (this.sort) {
            // TODO: GIM 10/6/19 - check sorting is correct
            // Check with raman
            result = result
                .sort((a, b) => (a[this.textField] > b[this.textField] ? 1 : -1))
                .sort((a, b) => (a._selected > b._selected ? 1 : -1));
        }

        return result;
    }

    getSelectedOptions(): any[] {
        if (
            this.value === null ||
            this.value === undefined ||
            this.options === null ||
            this.options === undefined ||
            this.options.length === 0 ||
            (this.multiple && !this.value.length)
        ) {
            return null;
        }

        if (this.multiple) {
            return (this.value as string[]).map(selectedItemId =>
                this.options.find(o => o[this.idField] === selectedItemId)
            );
        } else {
            // In feature panel (choice), field.value is always an array
            if (Array.isArray(this.value)) {
                if (this.value.includes(this.noSelectionText)) {
                    return null;
                } else if (this.value.length === 1) {
                    return [this.options.find(o => o[this.idField] === this.value[0])];
                } else if (this.value.length > 1) {
                    return this.value.map(selectedItemId => this.options.find(o => o[this.idField] === selectedItemId));
                }
            }

            return [this.options.find(o => o[this.idField] === this.value)].filter(Boolean);
        }
    }

    /**
     * Update the selected options text
     */
    updateText(): void {
        let str = '';

        let selectedOptions = this.getSelectedOptions();
        this.placeholderGreyText = false;

        if (selectedOptions) {
            selectedOptions.forEach(option => {
                str += option[this.textField] + ', ';
            });
            str = str.slice(0, -2);
        }

        if (!this.multiple && this.addIcon && selectedOptions && selectedOptions[0] && selectedOptions[0].iconClass) {
            this.selectedIconClass = selectedOptions[0].iconClass;
        }

        if (this.geomIcon && selectedOptions && selectedOptions[0] && this.hasGeomIconProperty(selectedOptions[0])) {
            this.updateSelectedGeomOption(selectedOptions[0]);
        }

        if (
            this.value === null ||
            this.value === undefined ||
            (this.multiple && !this.value.length) ||
            !this.value.length
        ) {
            str = this.handleEmptyValue();
        }

        this.selectedText = str || '';
    }

    updateOptionSelection(): void {
        const selectedOptions = this.getSelectedOptions();

        this.options.forEach(o => {
            o._selected =
                selectedOptions?.includes(o.idField) ||
                selectedOptions?.includes(o.textField) ||
                selectedOptions?.includes(o);
        });
    }

    get backgroundColorStyles(): { 'background-color': string } {
        let retValue = this.getSelectedOptions()[0];

        if (!retValue) {
            return null;
        }

        if (retValue.color) {
            if (retValue.color.indexOf('#') === -1) {
                return { 'background-color': 'rgb(' + retValue.color + ');' };
            } else {
                return { 'background-color': retValue.color + ';' };
            }
        } else {
            if (retValue.indexOf('#') === -1) {
                return { 'background-color': 'rgb(' + retValue + ');' };
            } else {
                return { 'background-color': retValue + ';' };
            }
        }
    }

    private hasGeomIconProperty(option: any): boolean {
        return option?.hasOwnProperty('color') || option?.hasOwnProperty('geometryType');
    }

    private updateSelectedGeomOption(option: any): void {
        this.selectedColor = option?.color;
        this.selectedGeometryType = option?.geometryType;
    }

    private handleEmptyValue(): string {
        if (this.geomIcon) {
            this.resetSelectGeomOption();
        }
        this.placeholderGreyText = true;
        return this.placeholder;
    }

    private resetSelectGeomOption(): void {
        this.selectedColor = null;
        this.selectedGeometryType = null;
    }

    private handleMultipleSelect(option: any): void {
        let index = this.value ? this.value.indexOf(option[this.idField]) : -1;
        if (index !== -1) {
            // Remove the existing value
            (this.value as string[]).splice(index, 1);
        } else {
            // Add the new value if it doesn't already exist
            if (!Array.isArray(this.value)) {
                this.value = [option[this.idField]];
            } else {
                const valueArray = this.value as string[];
                if (!valueArray.includes(option[this.idField])) {
                    valueArray.push(option[this.idField]);
                }
            }
        }

        // Keep 'Other' option at the end of the list
        this.value = this.value as string[];
        this.value = Array.from(
            (this.value as string[])
                .filter(v => v !== OtherWordstring)
                .concat(this.value.filter(v => v === OtherWordstring))
        );

        this.updateOptionSelection();
    }
}
