<ul
    #fieldListContainer
    #dropList="cdkDropList"
    class="control-group"
    cdkDropListOrientation="vertical"
    cdkDropList
    [cdkDropListEnterPredicate]="predicate"
    [cdkDropListData]="{ order: parentField ? 1 : 0 }"
    [cdkDropListConnectedTo]="connectedLists$ | async"
    (cdkDropListDropped)="drop($event)"
>
    <ng-container>
        <field-item
            *ngFor="let field of fields; index as i"
            [application]="application"
            [templateEditorConfig]="templateEditorConfig"
            [currentProjectDetails]="currentProjectDetails"
            [currentTab]="currentTab"
            [field]="field"
            [id]="field.name.replace(' ', '') + '-field-item'"
            [template]="template"
            [selectedField]="selectedField"
            cdkDrag
            [cdkDragDisabled]="isDragDisabled(field) || blockedDragOnInputElement"
            [cdkDragData]="{
                field: field,
                parentField: parentField,
                coords: parentField ? [getParentPosition(), i] : [i]
            }"
            (fieldSelected)="fieldSelected.emit($event)"
            (ruleChanged)="updateAllFields()"
            (duplicateField)="onDuplicateField($event)"
            (mouseover)="disableDraggingOnInputs()"
            (click)="disableDraggingOnInputs()"
        >
            <div class="drop-element-placeholder" *cdkDragPlaceholder></div>
        </field-item>
    </ng-container>
    <li *ngIf="!fields.length">
        <div class="drop-element"><span translate="TC.Common.DropFieldsHere"></span></div>
    </li>
</ul>
