import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { TranslationService } from 'src/app/core/translation/translation.service';
import {
    TemplatedFeature,
    TemplatedFeatureMetadataProperty
} from 'src/app/feature/map-viewer/side-panel/feature-panel/feature-fields/templated-feature';
import { PostProcessingStatus } from 'src/app/feature/post-processing/options/post-processing-options.component';
import { GspLoggerService } from 'src/app/log-handler.service';
import { ButtonType } from 'src/app/shared/common/components/buttons/button';
import { ActiveFeatureStreamsService } from 'src/app/shared/common/current-features/active-feature-streams.service';
import { FeaturesStore } from 'src/app/shared/common/current-features/features-store.service';
import { FeaturesStreamsService } from 'src/app/shared/common/current-features/features-streams.service';
import { LayersStore } from 'src/app/shared/common/current-layers/layers-store.service';
import { MapWorkspacesStoreService } from 'src/app/shared/common/current-map-workspaces/map-workspaces-store.service';
import { ProjectStreamService } from 'src/app/shared/common/current-project/project-stream.service';
import { CurrentUserStreamService } from 'src/app/shared/common/current-user/current-user-stream.service';
import { MenuService } from 'src/app/shared/common/layout/menu.service';
import { AccuracyUtils, UnitSystem } from 'src/app/shared/common/utility/accuracy-utils';
import { CloneUtils } from 'src/app/shared/common/utility/clone-utils';
import { DateUtils } from 'src/app/shared/common/utility/date-utils';
import { GeneralUtils } from 'src/app/shared/common/utility/general-utils';
import { GeometryTypes, GeometryUtils } from 'src/app/shared/common/utility/geometry-utils';
import { CachedFeatureService } from 'src/app/shared/map-data-services/feature/cached-feature.service';
import { Feature } from 'src/app/shared/map-data-services/feature/feature';
import { FeatureService, PostProcessingEventSummary } from 'src/app/shared/map-data-services/feature/feature.service';
import { Layer } from 'src/app/shared/map-data-services/layer/layer';
import { MapWorkspace } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace';
import { MapWorkspacePermissionType } from 'src/app/shared/map-data-services/mapWorkspace/map-workspace-permission';
import { Project } from 'src/app/shared/map-data-services/project/project';
import { FieldType, LayoutFieldType } from 'src/app/shared/template-services/field';
import { TemplateService } from 'src/app/shared/template-services/template.service';
import { User, UserRole } from 'src/app/shared/user/user';
import { ChartPanelStreamService } from '../../common/chart-panel-stream.service';
import { MapContentMonitor } from '../../common/map-content-monitor.service';
import { SelectionToolIdUtils, SelectionToolStream } from '../../common/selection-tool-stream.service';
import { FeatureMapLayersService } from '../../map-container/feature-map-layers/feature-map-layers.service';
import { SelectionAction, SelectionModeAction } from '../../toolbar/mapToolbar/selectionTools/selection-tool.component';
import { SidePanelStreamsService } from '../side-panel-streams.service';
import { SidePanelName } from '../side-panel.component';
import { FeatureField, GroupFeatureField } from './feature-fields/feature-field';
import { FeaturePanelUtil } from './feature-panel.util';

// A feature displayed in the feature panel
export type PanelFeature = TemplatedFeature & {
    hasTemplate?: boolean;
    isLayerRemoved?: boolean;
    removedLayer?: any;
    postProcessingEvents?: PostProcessingEventSummary;
};

const isTemplatedFeature = (feature: PanelFeature): feature is TemplatedFeature =>
    (feature as TemplatedFeature).fields !== undefined;

@Component({
    selector: 'feature-panel',
    templateUrl: './feature-panel.component.html'
})
export class FeaturePanelComponent implements OnInit, OnDestroy {
    // expose enums to template
    public ButtonType = ButtonType;
    public UserRole = UserRole;
    public MapWorkspacePermissionType = MapWorkspacePermissionType;
    public TemplatedFeatureMetadataProperty = TemplatedFeatureMetadataProperty;

    public PostProcessingStatus = PostProcessingStatus;

    private readonly destroyed = new Subject<void>();

    restoredLayerIds: string[] = []; // ids of layers that have been restored
    currentMapWorkspace: MapWorkspace;
    currentProject: Project;
    editMode: boolean;
    deleteMode: boolean;
    selectedFeatures: Feature[]; // the set of currently selected features
    activeFeature: Feature;
    activePanelFeature: PanelFeature; // the currently active (highlighted) feature in the feature panel
    removeFromSelectionMode = false; // true when selection tool is "-" option

    activePanelFeatureBeforeSaving: PanelFeature; // a copy of active feature in the feature panel before any edit changes
    currentUser: User;
    modifiedByUserDetails: User;
    showDeleteAllConfirmation = false;
    loading = true;
    showAccuracies = false;
    horizontalAccuracyValue: number;
    horizontalAccuracy: string;
    verticalAccuracyValue: number;
    verticalAccuracy: string;
    correctionStatus: string;
    collectedWorkspace: string;
    postProcessingInfoMessage = '';
    reservedTags = ['exported', 'No tags'];
    availableFeatureTags: string[] = [];
    selectedFeatureTags: string[] = [];
    tagEditMode = false;

    private mapWorkspaces: MapWorkspace[] = [];

    constructor(
        private featuresStore: FeaturesStore,
        private featuresStreams: FeaturesStreamsService,
        private featureService: FeatureService,
        private activeFeatureStreams: ActiveFeatureStreamsService,
        private sidePanelStreams: SidePanelStreamsService,
        private layersStore: LayersStore,
        private templateService: TemplateService,
        private messaging: MessagingService,
        private selectionToolStream: SelectionToolStream,
        private mapWorkspaceStore: MapWorkspacesStoreService,
        private currentUserStream: CurrentUserStreamService,
        private projectStream: ProjectStreamService,
        private cachedFeatureService: CachedFeatureService,
        private featureMapLayersService: FeatureMapLayersService,
        private menuService: MenuService,
        private mapContentMonitor: MapContentMonitor,
        private translateService: TranslationService,
        private chartPanelStreamService: ChartPanelStreamService,
        private logger: GspLoggerService
    ) {}

    ngOnInit(): void {
        this.currentMapWorkspace = this.mapWorkspaceStore.getCurrentMapWorkspace();
        this.currentProject = this.projectStream.getCurrentProject();
        this.projectStream
            .getProjectUser(this.currentUserStream.currentUser.id)
            .then(user => (this.currentUser = user));
        this.initSubscriptions();
    }

    ngOnDestroy(): void {
        this.destroyed.next(null);
    }

    private initSubscriptions(): void {
        this.selectionToolStream.selectionToolSelectedStream
            .pipe(takeUntil(this.destroyed))
            .subscribe(selectionToolId => {
                this.removeFromSelectionMode =
                    SelectionToolIdUtils.getActionFromSelectionToolId(selectionToolId) === SelectionAction.REMOVE;
            });

        this.featuresStreams.selectedFeaturesStream.pipe(takeUntil(this.destroyed)).subscribe(selectedFeatures => {
            if (!selectedFeatures.length) {
                this.featuresStreams.setActiveFeature(null);
                this.featuresStreams.clearSelectedFeatures();
                this.sidePanelStreams.closeSidePanel(SidePanelName.SELECTED_FEATURES);
            }
        });

        this.activeFeatureStreams.activeFeatureStream
            .pipe(takeUntil(this.destroyed))
            .subscribe(async (activeFeature: Feature) => {
                this.activeFeature = activeFeature;
                this.initCustomTags();
                await this.setActivePanelFeature();
                this.setCorrectionStatusAndGetEvents();
            });

        // Added to reset edit mode on menu change
        this.menuService.activeMenusStream.pipe(takeUntil(this.destroyed)).subscribe(() => {
            this.cancel();
        });

        this.mapWorkspaceStore.projectMapWorkspacesStream.pipe(takeUntil(this.destroyed)).subscribe(workspaces => {
            this.mapWorkspaces = workspaces;
            this.collectedWorkspace = this.getFeatureCollectedInWorkspaceName(
                this.activePanelFeature?.metadata?.collection_workspaceid
            );
        });
    }

    private getFeatureCollectedInWorkspaceName(workspaceId: string): string {
        const collectedWorkspace = this.mapWorkspaces
            ? this.mapWorkspaces.find(workspace => workspace.id === workspaceId)
            : null;
        return collectedWorkspace ? collectedWorkspace.name : '';
    }

    private async setActivePanelFeature(): Promise<void> {
        this.editMode = false;
        this.deleteMode = false;

        this.selectedFeatures = this.featuresStreams.selectedFeaturesStream.getValue();

        // set layer geometryType to feature if it doesn't exist
        this.selectedFeatures.map((feature: Feature) => {
            feature.geometryType = GeometryUtils.getGeometryType(
                feature.geometryType || this.layersStore.getMapWorkspaceLayerById(feature.layerId).geometryType
            );
        });

        if (this.activeFeature) {
            this.selectedFeatureTags = this.activeFeature.tags?.filter(tag => !this.reservedTags.includes(tag));

            // use feature from cache if more recent
            let cachedFeature = this.cachedFeatureService.updateFromCacheFeature(this.activeFeature);

            this.featureMapLayersService.updateFeatureMapLayer(cachedFeature, null, false);

            // get template if feature is templated...
            const template = this.activeFeature.collectedTemplateId
                ? await this.templateService.getLatestPublishedTemplateBySeriesId(
                      this.currentProject.id,
                      this.activeFeature.templateSeriesId
                  )
                : null;

            if (template !== null) {
                // and add template information
                this.activePanelFeature = new TemplatedFeature(this.activeFeature, template);
                this.activePanelFeature.hasTemplate = true;
            } else {
                this.activePanelFeature = this.activeFeature as unknown as PanelFeature; // force Feature to PanelFeature
                this.activePanelFeature.hasTemplate = false;
            }

            // update the removedLayer if in fact the layer has been restored
            this.activePanelFeature.isLayerRemoved = false;
            this.activePanelFeature.removedLayer = undefined;
            if (this.activeFeature.removedLayer) {
                this.activePanelFeature.isLayerRemoved =
                    this.activeFeature.isLayerRemoved &&
                    this.restoredLayerIds.indexOf(this.activeFeature.removedLayer.id) === -1;
                this.activePanelFeature.removedLayer = this.activeFeature.removedLayer;
            }

            // load User who last updated feature based on metadata.collection_updatedBy
            if (
                !this.currentMapWorkspace.isPubliclySharedMapWorkspace &&
                this.activeFeature.metadata?.collection_updatedBy
            ) {
                const user = await this.projectStream.getProjectUser(this.activeFeature.metadata.collection_updatedBy);
                this.modifiedByUserDetails = user;
            }

            this.collectedWorkspace = this.getFeatureCollectedInWorkspaceName(
                this.activePanelFeature.metadata.collection_workspaceid
            );

            this.calculateAccuracies();

            this.showAccuracies =
                !this.activePanelFeature.properties?.correctionstatus?.toLowerCase().includes('digitized') &&
                (!GeneralUtils.isNullUndefinedOrNaN(this.horizontalAccuracy) ||
                    !GeneralUtils.isNullUndefinedOrNaN(this.verticalAccuracy));

            // save a copy of feature for change detection
            this.activePanelFeatureBeforeSaving = CloneUtils.cloneDeep(this.activePanelFeature);
            this.loading = false;
        }
    }

    public setCurrentFeature(feature: PanelFeature): void {
        const currentSelectionToolId: SelectionModeAction =
            this.selectionToolStream.selectionToolSelectedStream.getValue();
        if (SelectionToolIdUtils.getActionFromSelectionToolId(currentSelectionToolId) === SelectionAction.REMOVE) {
            this.featuresStreams.removeFromSelectedFeatures([feature.id]);
        } else {
            const tmpFeature = this.cachedFeatureService.getFeature(feature.id);
            this.featuresStreams.setActiveFeature(tmpFeature, true);
        }
    }

    public closeSidePanel(): void {
        this.featuresStreams.setActiveFeature(null);
        this.featuresStreams.clearSelectedFeatures();
    }

    public cancel(): void {
        this.activePanelFeature = CloneUtils.cloneDeep(this.activePanelFeatureBeforeSaving);
        this.editMode = false;
    }

    public cancelDelete(): void {
        this.deleteMode = false;
    }

    public hasError(): boolean {
        let errorGroupFields: FeatureField[] = [];
        let errorField = isTemplatedFeature(this.activePanelFeature)
            ? this.activePanelFeature.fields.filter(field => {
                  if (field.type !== LayoutFieldType.Group) {
                      return field.hasError;
                  } else {
                      let fields = (field as GroupFeatureField).fields.filter(innerField => innerField.hasError);
                      errorGroupFields = _.concat(errorGroupFields, fields);
                  }
              })
            : [];
        return errorField.length > 0 || errorGroupFields.length > 0;
    }

    public deleteAction = (): Promise<void> => this.delete();
    public deleteSelectionAction = (): Promise<void> => this.deleteSelection();

    public delete(): Promise<void> {
        return this.featuresStore.deleteFeature(this.activePanelFeature).then(
            () => {
                const cachedFeature = this.cachedFeatureService.getFeature(this.activePanelFeature.id);
                const layer = this.layersStore.getLayerKeyToLayerMap()[cachedFeature.layerKey];
                this.featureMapLayersService.updateFeatureMapLayer(cachedFeature, null, false);
                this.layersStore.refreshLayerInStore(layer.projectId, layer, layer.visible);
                this.featuresStreams.removeFromSelectedFeatures(this.activePanelFeature.id);
                this.mapContentMonitor.mapRefreshed();
                this.messaging.showSuccess(this.translateService.instant('TCS.Mapviewer.Feature.DeleteSuccess'));
            },
            () => {
                this.messaging.showError(this.translateService.instant('TCS.Mapviewer.Features.DeleteError'));
            }
        );
    }

    public showDeleteAllConfirmationAction(): void {
        this.showDeleteAllConfirmation = true;
    }

    public cancelDeleteSelection(): void {
        this.showDeleteAllConfirmation = false;
    }

    public saveAction = (): Promise<void> => this.save();

    private async save(): Promise<void> {
        if (await this.isFeatureOutOfSync()) {
            this.messaging.showErrorWithAction(
                this.translateService.instant('TC.Common.FormOutOfSync'),
                null,
                'TC.Common.Ok',
                { enableHtml: true }
            );
            this.featuresStreams.refreshSelectedFeatures();
            return;
        }

        if (isTemplatedFeature(this.activePanelFeature)) {
            _.forEach(this.activePanelFeature.fields, field => {
                // Skip and ignore pageheader fields in DTO
                if (field.type === LayoutFieldType.PageHeader) {
                    return;
                }
                if (field.type !== LayoutFieldType.Group) {
                    this.activePanelFeature.properties[field.name] = this.getFeaturePropertyValueFromFields(field);
                    if (field.type === FieldType.Date) {
                        field.value = this.activePanelFeature.properties[field.name];
                    }
                } else {
                    (field as GroupFeatureField).fields.forEach(fieldInGroup => {
                        this.activePanelFeature.properties[fieldInGroup.name] =
                            this.getFeaturePropertyValueFromFields(fieldInGroup);
                        if (fieldInGroup.type === FieldType.Date) {
                            fieldInGroup.value = this.activePanelFeature.properties[fieldInGroup.name];
                        }
                    });
                }
            });
        }

        if (
            JSON.stringify(this.activePanelFeatureBeforeSaving.properties) !==
            JSON.stringify(this.activePanelFeature.properties)
        ) {
            this.activePanelFeature = this.getUpdatedFeatureMetadata(this.activePanelFeature) as PanelFeature;
            this.featuresStore.saveFeature(this.activePanelFeature);
        } else {
            this.messaging.showInfo(this.translateService.instant('TC.Common.NoChangesMade'));
        }
        this.activePanelFeatureBeforeSaving = CloneUtils.cloneDeep(this.activePanelFeature);
        this.editMode = false;
    }

    private async isFeatureOutOfSync(): Promise<boolean> {
        const cachedFeature = this.cachedFeatureService.getFeature(this.activePanelFeature.id);
        return !(await this.featuresStore.checkFeatureInSync(cachedFeature));
    }

    public async restoreLayer(restoredLayer: Layer): Promise<Layer> {
        restoredLayer.projectId = this.currentProject.id;
        try {
            const layer = await this.layersStore.addLayerToMapWorkspace(
                this.currentProject.id,
                this.currentMapWorkspace.id,
                restoredLayer
            );
            this.messaging.showSuccess(this.translateService.instant('TCS.Mapviewer.LayerPanel.RestoreSuccess'));
            this.restoredLayerIds.push(restoredLayer.id);
            this.activePanelFeature.isLayerRemoved = false;
            this.layersStore.restoreLayer(this.currentProject.id, layer);
            return restoredLayer;
        } catch (e) {
            this.messaging.showError(this.translateService.instant('TC.Common.ErrorWhileRestoring'));
            return null;
        }
    }

    private getUpdatedFeatureMetadata(feature: PanelFeature | Feature): PanelFeature | Feature {
        const editedFeature = feature;
        const currentUser: User = this.currentUserStream.currentUser;
        const dateUpdated = DateUtils.utcDateFormat(new Date());
        editedFeature.metadata.collection_utc = dateUpdated;
        // Will need to update collection_geometry_utc as well if Map Viewer updates geometry in the future
        editedFeature.metadata.collection_attributes_utc = dateUpdated;
        editedFeature.metadata.collection_updatedBy = currentUser.id;
        // TODO: make modifiedByUserDetails part of panel feature
        this.modifiedByUserDetails = currentUser;
        return editedFeature;
    }

    private deleteSelection(): Promise<void> {
        return this.featuresStore.deleteAllFeature(this.selectedFeatures).then(
            () => {
                const layerKeys: string[] = [];
                this.selectedFeatures.forEach(feature => {
                    const cachedFeature = this.cachedFeatureService.getFeature(feature.id);
                    this.featureMapLayersService.updateFeatureMapLayer(cachedFeature, null, false);
                    layerKeys.push(cachedFeature.layerKey);
                });
                const layerMap = this.layersStore.getLayerKeyToLayerMap();
                _.uniq(layerKeys).forEach(key => {
                    const layer = layerMap[key];
                    this.layersStore.refreshLayerInStore(layer.projectId, layer, layer.visible);
                });
                this.featuresStreams.removeFromSelectedFeatures(this.selectedFeatures.map(feature => feature.id));
                this.mapContentMonitor.mapRefreshed();
                this.messaging.showSuccess(this.translateService.instant('TCS.Mapviewer.Features.DeleteSuccess'));
                this.showDeleteAllConfirmation = false;
            },
            () => {
                this.showDeleteAllConfirmation = false;
                this.messaging.showError(this.translateService.instant('TCS.Mapviewer.Features.DeleteError'));
            }
        );
    }

    private getFeaturePropertyValueFromFields(field: FeatureField): any {
        let propertyValue: any;
        if (field.type === FieldType.Choice) {
            propertyValue = field.value && field.value.length ? field.value.join('|') : null;
        } else if (field.type === FieldType.Image || field.type === FieldType.Signature) {
            propertyValue = field.value ? field.value.join('|') : field.value;
        } else if (field.type === FieldType.Date) {
            propertyValue = null;
            if (field.value) {
                propertyValue = field.value;
            }
        } else {
            propertyValue = field.value;
        }
        return propertyValue;
    }

    public edit(): void {
        this.editMode = true;
        this.deleteMode = false;

        this.chartPanelStreamService.isSummaryPanelDisplayed = false;
        this.chartPanelStreamService.summaryPanelActiveMapStream.next({});

        this.isFeatureOutOfSync().then(isOutOfSync => {
            if (isOutOfSync) {
                this.messaging.showWarning(this.translateService.instant('TC.Common.FormRefreshed'));
                this.featuresStreams.refreshSelectedFeatures();
            }
        });
    }

    private async refreshActivePanelFeature(featureId: string): Promise<void> {
        try {
            // Refresh active panel feature after triggering pp action
            const feature = await this.featureService.getFeatureById(this.currentProject.id, featureId);
            feature.layerKey = feature.getLayerKey();
            const layer = this.layersStore.getLayerKeyToLayerMap()[feature.layerKey];
            feature.addLayerProperties(layer);
            this.cachedFeatureService.addOrUpdateFeature(feature);
            this.featuresStreams.setActiveFeature(feature);
        } catch (error) {
            throw error;
        }
    }

    async updatePostProcessingMetadata(itemOverwriting: string, itemBeingOverwritten: string): Promise<void> {
        const currentFeature = CloneUtils.cloneDeep(this.activeFeature);
        try {
            if (!currentFeature.localGeometry) {
                await this.featureService.getLocalGeometryAndCRS(this.currentProject.id, currentFeature);
            }
            let updatedFeature = FeaturePanelUtil.getUpdatedPostProcessedFeature(
                currentFeature,
                itemOverwriting,
                itemBeingOverwritten
            );
            updatedFeature = this.getUpdatedFeatureMetadata(updatedFeature) as Feature;

            // Save feature and refresh panel
            await this.featuresStore.saveFeature(updatedFeature);
            await this.refreshActivePanelFeature(updatedFeature.id);
            this.mapContentMonitor.refreshMapContentStream.next(null);
        } catch (error) {
            this.logger.error(error);
        }
    }

    calculateAccuracies(): void {
        const { settings } = this.currentProject;
        if (settings) {
            const unitSystem =
                settings.unitSettings.unitsystem === UnitSystem.METRIC ? UnitSystem.METRIC : UnitSystem.IMPERIAL;
            const geometryType = GeometryUtils.getGeometryType(this.activePanelFeature.geometryType);

            this.horizontalAccuracyValue =
                geometryType === GeometryTypes.POINT
                    ? this.activePanelFeature.metadata.common_averageHorizontal
                    : this.activePanelFeature.metadata.common_worstHorizontal;
            this.horizontalAccuracy = AccuracyUtils.calculateAccuracyForUnitSystem(
                this.horizontalAccuracyValue,
                unitSystem
            );
            this.verticalAccuracyValue =
                geometryType === GeometryTypes.POINT
                    ? this.activePanelFeature.metadata.common_averageVertical
                    : this.activePanelFeature.metadata.common_worstVertical;
            this.verticalAccuracy = AccuracyUtils.calculateAccuracyForUnitSystem(
                this.verticalAccuracyValue,
                unitSystem
            );
        }
    }

    private setCorrectionStatusAndGetEvents(): void {
        this.postProcessingInfoMessage = '';
        this.correctionStatus = FeaturePanelUtil.getFeatureCorrectionStatus(this.activeFeature);
        if (
            this.activeFeature.metadata?.processing_postprocessedStatus === PostProcessingStatus.PENDING &&
            this.activeFeature.metadata?.processing_eventscallbackurl
        ) {
            this.featureService
                .getPostProcessingEventsForFeature(this.activeFeature.metadata?.processing_eventscallbackurl)
                .then(events => {
                    this.activePanelFeature.postProcessingEvents = events;
                    this.postProcessingInfoMessage = FeaturePanelUtil.constructInfoMessage(
                        this.activePanelFeature,
                        this.translateService
                    );
                });
        }
    }

    private initCustomTags(): void {
        this.availableFeatureTags = this.mapWorkspaceStore.workspaceFeatureTagsStream.getValue();
        this.selectedFeatureTags = this.activeFeature.tags?.filter(tag => !this.reservedTags.includes(tag));
    }

    public addTag(newTag: string): void {
        const previousTagSet = this.activeFeature.tags;

        this.activeFeature.tags = [...this.selectedFeatureTags, newTag];
        this.activeFeatureStreams.activeFeature = this.activeFeature;

        // Prevents loss of tag changes if activeFeatureStream changes before updating cachedFeatureService.
        // Avoids updating activeFeatureStream directly to prevent costly updates in subscribers like field-summary.
        this.featuresStore.updateCachedFeature(this.activeFeature);

        this.featureService.addFeatureTag(this.activePanelFeature.id, this.currentProject.id, newTag).then(success => {
            if (success) {
                if (!this.availableFeatureTags.includes(newTag)) {
                    this.mapWorkspaceStore.workspaceFeatureTagsStream.next(
                        [...this.availableFeatureTags, newTag].sort((a, b) => a.localeCompare(b))
                    );
                } else {
                    this.mapWorkspaceStore.workspaceFeatureTagsStream.next([...this.availableFeatureTags]);
                }
            } else {
                this.activeFeature.tags = previousTagSet;
                this.selectedFeatureTags = previousTagSet;
            }
        });
    }

    public removeTag(tagToRemove: string): void {
        const previousTagSet = this.activeFeature.tags;

        this.activeFeature.tags = this.selectedFeatureTags.filter(tag => tag !== tagToRemove);
        this.activeFeatureStreams.activeFeature = this.activeFeature;

        this.featuresStore.saveFeature(this.activeFeature);

        this.featureService
            .removeFeatureTag(this.activePanelFeature.id, this.currentProject.id, tagToRemove)
            .then(success => {
                if (!success) {
                    this.activeFeature.tags = previousTagSet;
                    this.selectedFeatureTags = previousTagSet;
                }
            });
    }
}
