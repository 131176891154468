import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/core/translation/translation.service';
import { OtherWordstring } from 'src/app/shared/common/components/gsp-dropdown/gsp-dropdown.component';
import { StringUtils } from 'src/app/shared/common/utility/string-utils';
import { ChoiceFeatureField } from '../feature-field';

@Component({
    templateUrl: './choice-field.component.html',
    selector: 'choice-field'
})
export class ChoiceFieldComponent implements OnInit, AfterViewInit {
    @Input()
    field: ChoiceFeatureField;

    @Input()
    editMode: boolean;

    public _isOptionSelected: { [option: string]: boolean };
    public _isOtherOptionSelected: boolean;
    public _otherOption: string;
    private otherWordstring = OtherWordstring;
    public panelFooterHeight = 75;

    public showOtherForm = false;
    public selectedOptions: string[] = [];
    public selectedOthers: string[] = [];
    public noSelectionMark = '---';

    constructor(private cdRef: ChangeDetectorRef, private translate: TranslationService) {}

    ngOnInit(): void {
        const domainValues = this.field.value.filter(value => this.field.options.includes(value));
        const nonDomainValues = this.field.value.filter(value => !this.field.options.includes(value) && value !== '');
        const valueIncludesNonDomainValues = this.field.value.some(
            value => !this.field.options.includes(value) && value !== ''
        );

        this._isOptionSelected = {};
        this.selectedOptions = domainValues;
        this.selectedOthers = nonDomainValues;
        this._isOtherOptionSelected = valueIncludesNonDomainValues;
        this._otherOption = this.stringifiedOtherOption();
        this.showOtherForm = this.field.allowNonDomainValues;

        this.otherWordstring = this.translate.instant('TCW_OTHER');

        this.setFromFieldValue();
    }

    ngAfterViewInit(): void {
        this.cdRef.detectChanges();
    }

    private setFromFieldValue(): void {
        if (this.showOtherForm) {
            if (!this.field.options.includes(this.otherWordstring)) {
                this.field.options.push(this.otherWordstring);
            } else if (this._isOtherOptionSelected && !this.selectedOptions.includes(this.otherWordstring)) {
                this.selectedOptions.push(this.otherWordstring);
            }
        }

        this.field.options.forEach(option => {
            this._isOptionSelected[option] = this.field.value.includes(option);
        });
        this.checkOptions();
    }

    private setToFieldValue(): void {
        let selected = Object.keys(this._isOptionSelected).filter(
            option => this._isOptionSelected[option] && option !== this.otherWordstring
        );
        if (this._isOtherOptionSelected) {
            selected = selected.concat(this._otherOption.split('|'));
        }
        this.field.value = selected;
        this.checkOptions();
    }

    public isSelected(option: string): boolean {
        return this._isOptionSelected[option];
    }

    public get isOtherOptionSelected(): boolean {
        return this._isOtherOptionSelected;
    }

    public toggleOtherOptionSelected(): void {
        this.clearAllOptionsIfAllowMultiSelect();
        this._isOtherOptionSelected = !this._isOtherOptionSelected;
        this.setToFieldValue();
    }

    private clearAllOptionsIfAllowMultiSelect(): void {
        if (!this.field.allowMultiSelect) {
            Object.keys(this._isOptionSelected).forEach(option2 => {
                this._isOptionSelected[option2] = false;
            });
            this._isOtherOptionSelected = false;
        }
    }

    public get otherOption(): string {
        if (this._isOtherOptionSelected) {
            // if unselected "other" option but "other field" has a value, select it as "Other" automatically.
            const selectOther = !this.selectedOptions?.includes(this.otherWordstring)
                ? this.selectedOptions.push(this.otherWordstring)
                : null;

            this.checkOtherField();
            return this._otherOption;
        } else {
            return null;
        }
    }

    public set otherOption(option: string) {
        option = StringUtils.cleanupWhitespace(option);
        this._otherOption = option;
        this.setToFieldValue();
        this.checkOtherField();
        this.cdRef.detectChanges();
    }

    public selectionChange(selectedOptions: string[]): void {
        this.field.options.forEach(option => {
            this._isOptionSelected[option] = selectedOptions?.includes(option);

            this._isOtherOptionSelected = this._isOptionSelected[option]
                ? option === this.otherWordstring &&
                  selectedOptions.includes(this.otherWordstring) &&
                  this.field.allowNonDomainValues
                : false;
        });

        if (this._isOtherOptionSelected) {
            this._otherOption = this.stringifiedOtherOption();
        }

        this.showOtherForm = this._isOtherOptionSelected;

        this.setToFieldValue();
        this.cdRef.detectChanges();
    }

    private stringifiedOtherOption(): string {
        return this.selectedOthers.join('|').replace(/\|$/, '');
    }

    private checkOptions(): void {
        // disable "save" button if required and no value has been set.
        this.field.hasError = this.field.required && !this.field.value.length;
    }

    private checkOtherField(): void {
        // disable "save" button if required and no other value has no string.
        this.field.hasError = this.field.required && !this._otherOption?.length;
    }

    public fieldOptionsWithoutOthers(): string[] {
        return this.field.options.filter(option => option !== this.otherWordstring);
    }
}
