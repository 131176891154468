<div
    class="{{ dropdownContainerClass }} {{ dropdownUniqueClass }}"
    #dropdownContainer
    [ngClass]="{ open: showDropdown }"
>
    <a
        #toggleButton
        class="toggle-button btn-plain dd-toggle {{ addIcon }}"
        (click)="toggleDropdown($event)"
        [ngClass]="{ placeholder: value === null || value === undefined, 'show-inactive': ngDisabled }"
        *ngIf="!multiple"
    >
        <div *ngIf="addIcon && selectedIconClass" class="dropdown-icon">
            <span class="{{ selectedIconClass }} i32"></span>
        </div>
        <div *ngIf="geomIcon && selectedColor && selectedGeometryType" class="dropdown-icon">
            <geom-icon path="sym_32-layer-" [color]="selectedColor" [geoType]="selectedGeometryType"> </geom-icon>
        </div>
        <span
            class="toggle-text"
            [ngClass]="{ placeholder: placeholderGreyText }"
            title="{{ selectedText | translate }}"
            >{{ selectedText | translate }}</span
        >
        <i class="i32 icon_line_arrow_down"></i>
    </a>

    <div class="multi-selected-options" (click)="toggleDropdown($event)" *ngIf="multiple">
        <div class="collected-selections">
            <ul>
                <li #dropdownElement *ngFor="let option of value; let last = last">
                    <span class="text">{{ option }}<span *ngIf="!last">, </span></span>
                </li>
                <li #dropdownElement class="placeholder" *ngIf="!value?.length">
                    {{ multiPlaceholder | translate }}
                </li>
            </ul>
        </div>
        <i class="i32 icon_line_arrow_down"></i>
    </div>

    <div #dropdownMenu [ngClass]="{ 'overlap-dropdown-menu': overlapMenuOn }" class="dropdown-menu {{ overlapClass }}">
        <div #searchBox class="search-box" [hidden]="!showSearchInput || hideSearchBox">
            <input
                [(ngModel)]="filterQuery"
                type="text"
                placeholder="{{ searchPlaceholder | translate }}"
                class="search-input form-control"
            />
        </div>
        <ul>
            <li
                #dropdownElement
                class="dd-li-item"
                *ngFor="let option of filteredOptions; index as i"
                [ngClass]="{
                    selected: option._selected,
                    'multiple-selected': multiple,
                    'hide-option': option.hideOption,
                    'dd-item-color': addBg
                }"
                [ngStyle]="addBg ? backgroundColorStyles : null"
                [tabindex]="i"
            >
                <div class="dd-inner-li {{ option[idField] }}" (click)="select($event, option)">
                    <div class="mark-selected" *ngIf="multiple && option._selected">
                        <i class="modus-icons">check</i>
                    </div>
                    <div class="dropdown-icon" *ngIf="addIcon && option?.iconClass">
                        <span class="{{ option.iconClass }} i32"></span>
                    </div>
                    <div class="dropdown-icon" *ngIf="geomIcon && (option?.color || option?.geometryType)">
                        <geom-icon path="sym_32-layer-" [color]="option.color" [geoType]="option.geometryType">
                        </geom-icon>
                    </div>
                    <div class="option-label text-overflow" title="{{ option[textField] | translate }}">
                        {{ option[textField] | translate }}
                    </div>
                    <div class="option-description" *ngIf="addDescription" translate>
                        {{ option.description }}
                    </div>

                    <i [hidden]="!option._selected" class="ui-icon check-sm"></i>
                </div>
            </li>
            <li #dropdownElement *ngIf="emptyText && !this.filteredOptions.length" class="dropdown-empty-text">
                <div class="empty-text" translate>{{ emptyText }}</div>
            </li>
        </ul>
    </div>
</div>
